<template>
  <div>
    <b-card class="m-5">
      <template #header>
        <h1>{{ $t('accessibility.label') }}</h1>
      </template>
      <b-card-text>
        <div v-if="language === 'de'">
          <p>
            Der Anbieter dieses Webangebots (Zentrum für Nationales Hochleistungsrechnen Erlangen) ist bemüht, das
            Angebot im Einklang mit der <a href="http://gesetze-bayern.de/Content/Document/BayBGG/true">gültigen
            Gesetzgebung</a> barrierefrei zugänglich zu machen.
          </p>

          <p>
            Diese Erklärung zur Barrierefreiheit gilt für die Webauftritte und Anwendungen, die unter
            <strong>portal.hpc.fau.de</strong> verfügbar sind.
          </p>

          <h2>Stand der Vereinbarkeit mit den Anforderungen ("Konformitätsstatus")</h2>

          <b-alert variant="danger" show>
            Unbekannt: Der Inhalt wurde nicht bewertet oder die Bewertungsergebnisse sind nicht verfügbar.
          </b-alert>

          <h2>Hilfe und Kontaktaufnahme bei Problemen</h2>

          <p>
            Sie können Mängel bei der Einhaltung der Anforderungen an die Barrierefreiheit mitteilen oder Informationen,
            die nicht barrierefrei dargestellt werden müssen, barrierefrei anfordern.
          </p>

          <h3>Kontaktperson</h3>

          <p>Dr. Jan Eitzinger</p>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>

          <h2>Durchsetzungsverfahren</h2>

          <p>
            Bleibt eine Anfrage über die Kontaktmöglichkeit innerhalb von sechs Wochen ganz oder teilweise
            unbeantwortet, prüft die zuständige Aufsichtsbehörde auf Antrag des Nutzers, ob im Rahmen der Überwachung
            gegenüber dem Verpflichteten Maßnahmen erforderlich sind.
          </p>

          <p>
            Kontaktinformation der für das Durchsetzungsverfahren zuständigen Aufsicht:
          </p>

          <p><strong>Landesamt für Digitalisierung, Breitband und Vermessung</strong></p>

          <address>
            St.-Martin-Straße 47<br/>
            81541 München
          </address>

          <p>
            Tel.: +49 89 2129-1111<br/>
            Fax: +49 89 2129-1113<br/>
            E-Mail: <a href="mailto:bitv@bayern.de">bitv@bayern.de</a><br/>
            Web: <a href="https://www.ldbv.bayern.de/digitalisierung/bitv.html">https://www.ldbv.bayern.de/digitalisierung/bitv.html</a>
          </p>
        </div>
        <div v-else>
          <b-alert variant="warning" show>
            <strong>Disclaimer:</strong> The translation of this page is only provided for convenience. If there is any
            contradiction between the German and English version, the German language version shall take pre-cedence.
            Any discrepancies or differences created in the translation are not binding and have no legal effect.
          </b-alert>

          <p>
            Public authorities are obliged to make websites and mobile applications accessible in accordance with
            Directive (EU) 2016/2102 of the European Parliament and of the Council. The following accessibility
            statement documents the implementation status of this website in accordance with the <a
            href="http://gesetze-bayern.de/Content/Document/BayBGG/true">applicable legislation</a>.
          </p>

          <p>
            The accessibility statement belongs to the following website: <strong>portal.hpc.fau.de</strong>
          </p>

          <h2>Compliance status</h2>

          <b-alert variant="danger" show>
            Unknown: Content was not rated or rating results are not available.
          </b-alert>

          <h2>Help and contact in case of problems</h2>

          <p>
            You may have shortcomings in meeting the requirements notify the accessibility or information that is not
            accessible be presented barrier-free.
          </p>

          <h3>Contact Person</h3>

          <p>Dr. Jan Eitzinger</p>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>

          <h2>Enforcement procedures</h2>

          <p>
            Remains a request for the possibility of contact within six weeks in full or in part unanswered, the
            competent
            authority checks at the request of the user, whether in the context of Monitoring against the operators of
            the
            web offer measures are required.
          </p>

          <p>
            Contact information of the enforcement oversight body:
          </p>

          <p><strong>Landesamt für Digitalisierung, Breitband und Vermessung</strong></p>

          <address>
            St.-Martin-Straße 47<br/>
            81541 München
          </address>

          <p>
            Tel.: +49 89 2129-1111<br/>
            Fax: +49 89 2129-1113<br/>
            E-Mail: <a href="mailto:bitv@bayern.de">bitv@bayern.de</a><br/>
            Web: <a href="https://www.ldbv.bayern.de/digitalisierung/bitv.html">https://www.ldbv.bayern.de/digitalisierung/bitv.html</a>
          </p>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Accessibility',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  computed: {
    language () {
      return this.$root.$i18n.locale
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0px;
}

h2 {
  font-size: 1.15rem;
  font-weight: bold;
}

h3 {
  font-size: 1.05rem;
  font-weight: bold;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
